export const IP = "https://hp.clonex.dk";

export function api(type) {
  return fetch(`${IP}/api.php?type=${type}`)
    .then((d) => d.json())
    .catch((_) => false);
}

export function getItems() {
  return fetch("https://hundeparken.net/api/graphql", {
    headers: {
      accept: "*/*",
      "accept-language": "da-DK,da;q=0.9,en-US;q=0.8,en;q=0.7,de;q=0.6",
      "cache-control": "no-cache",
      "content-type": "application/json",
      pragma: "no-cache",
      priority: "u=1, i",
      "sec-ch-ua":
        '"Chromium";v="124", "Google Chrome";v="124", "Not-A.Brand";v="99"',
      "sec-ch-ua-mobile": "?0",
      "sec-ch-ua-platform": '"Windows"',
      "sec-fetch-dest": "empty",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "cross-site",
    },
    referrerPolicy: "strict-origin-when-cross-origin",
    body: '{"operationName":"GetOwnedItems","variables":{},"query":"query GetOwnedItems {\\n  ownedItems {\\n    id\\n    nameNo\\n    usageTypes\\n    gfxList\\n    __typename\\n  }\\n}"}',
    method: "POST",
    mode: "cors",
    credentials: "omit",
  })
    .then((d) => d.json())
    .then(({ data }) => {
      return {
        data: (data.ownedItems || []).map((item) => ({
          name: item.nameNo,
          id: item.gfxList.at(0).toString(),
        })),
      };
    })
    .catch((_) => false);
}

export function isNumeric(value) {
  return /^-?\d+$/.test(value);
}
