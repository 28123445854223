export default function Image(props) {
  const zoom = props.zoom || 1;

  return (
    <img
      src={`https://hundeparken.net/h5/game/gfx/item/${props.id}.png`}
      style={{ zoom }}
      alt=""
      loading="lazy"
    />
  );
}
