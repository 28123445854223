import React from "react";

export default class DropDownButton extends React.Component {
  state = {
    showOptions: false,
  };

  position(elem) {
    var left = 0,
      top = 0;

    do {
      left += elem.offsetLeft - elem.scrollLeft;
      top += elem.offsetTop - elem.scrollTop;
    } while ((elem = elem.offsetParent)); // eslint-disable-line

    return { left, top };
  }
  render() {
    const selected = this.props.selected;
    return (
      <div className={this.props.options.length > 0 ? "" : "disabled"}>
        <button
          onClick={(e) => {
            if (this.props.options.length === 1) {
              this.props.click(this.props.options[0].id);
            } else {
              this.props.showMenu(
                this.position(e.target).top,
                e.target.getBoundingClientRect().top
              ); //e.target.getBoundingClientRect().top);
            }
          }}
        >
          <i
            className={selected ? "fas fa-minus-circle" : "fas fa-plus-circle"}
            style={selected ? { color: "#dc0909" } : {}}
          ></i>
        </button>
        {this.state.showOptions && (
          <div className="dropdown">
            {this.props.options.map((d) => (
              <div
                className="dropdownOption"
                key={d.id}
                onClick={() => {
                  this.setState({
                    showOptions: false,
                  });
                  this.props.click(d.id);
                }}
              >
                {d.text}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
