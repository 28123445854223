import React from "react";
import { getItems } from "./helpers";

import Box from "./Box";
import Image from "./Image";
import DropDownButton from "./DropdownButton";

export default class App extends React.Component {
  LOCAL_KEY = "data2";
  EMPTY_BOX = {
    title: "Navn",
    hats: [],
  };
  defaultState = {
    selected: [],
    selectedWish: [],

    backpack: "Rygsæk",
    wishlist: "Ønsker",

    titleColor: "#696969",
    bgColor: "#fceeff",
    boxColor: "#eef4ff",
    rowSize: 12,
    boxSize: 650,
    imageZoom: 100,
    showNames: false,
    search: "",

    dropdownOffset: 0,
    dropdownOptions: [],

    boxes: [
      {
        title: "Rygsæk",
        hats: [],
      },
      {
        title: "Ønsker",
        hats: [],
      },
    ],
  };
  state = {
    data: false,
    ...this.defaultState,
  };
  searchRef;
  started = [];

  async componentDidMount() {
    const items = await getItems();
    if (items) {
      this.setState({
        data: items.data.map((d, i) => ({ ...d, i })),
      });
    }

    const local = localStorage.getItem(this.LOCAL_KEY);

    if (local) {
      try {
        let data = JSON.parse(window.atob(local));

        // console.log("Got data", data);

        if (!data.boxes) {
          // Convert
          data.boxes = [...this.defaultState.boxes].map((d) => ({ ...d }));
          data.boxes[0].title = data.backpack;
          data.boxes[0].hats = data.selected; //.map(id => this.state.data.find(d => d.id === id));

          data.boxes[1].title = data.wishlist;
          data.boxes[1].hats = data.selectedWish; //.map(id => this.state.data.find(d => d.id === id));
        }
        // console.log("Data", data);

        delete data.selected;
        delete data.selectedWish;

        this.setState(data);
      } catch (error) {}
    }
  }

  addBox() {
    this.setState({
      boxes: [...this.state.boxes, { ...this.EMPTY_BOX }],
    });
  }

  toggleItem(id, type) {
    const target = type === "wish" ? 1 : 0;
    const exists = this.state.boxes[target].hats.some((d) => d === id);
    let selected = [...this.state.boxes[target].hats];

    if (exists) {
      selected = selected.filter((d) => d !== id);
    } else {
      selected.push(id);
    }

    this.updateBox(target, "hats", selected);
  }

  search(data) {
    return this.state.search.length > 0
      ? data.filter((d) => {
          const name = d.name.toLowerCase();
          return name.includes(this.state.search);
        })
      : data;
  }

  updateState(newState) {
    this.setState(newState);
    requestAnimationFrame(() =>
      localStorage.setItem(this.LOCAL_KEY, this.exportData())
    );
  }

  exportData() {
    let data = {
      ...this.state,
    };

    // data.selected = data.selected.map(d => this.state.data[d].id);
    // data.selectedWish = data.selectedWish.map(d => this.state.data[d].id);

    delete data.data;
    delete data.search;

    return window.btoa(JSON.stringify(data));
  }

  import() {
    if (this.refs.textarea.value.length > 0) {
      try {
        let data = JSON.parse(window.atob(this.refs.textarea.value));
        data.selected = data.selected.map((id) =>
          this.state.data.findIndex((d) => d.id === id)
        );
        data.selectedWish = data.selectedWish.map((id) =>
          this.state.data.findIndex((d) => d.id === id)
        );

        this.setState(data);
      } catch (error) {
        alert("Dataen kunne ikke indlæses.");
      }
    } else {
      alert("Data feltet skal udfyldes.");
    }
  }

  updateBox(i, key, value) {
    const boxes = [...this.state.boxes];
    boxes[i][key] = value;

    this.updateState({
      boxes,
    });
  }

  reset() {
    const check = window.confirm(
      "Er du sikker på at du vil nulstille alle hatte og indstillinger?"
    );
    if (check) {
      this.setState(this.defaultState);
    }
  }

  position(elem) {
    var left = 0,
      top = 0;

    do {
      left += elem.offsetLeft - elem.scrollLeft;
      top += elem.offsetTop - elem.scrollTop;
    } while ((elem = elem.offsetParent));

    return { left, top };
  }

  render() {
    if (!this.state.data) {
      return <div className="loading">Loading...</div>;
    }

    const boxOptions = this.state.boxes.map((box, i) => ({
      id: i,
      text: box.title,
      hats: box.hats,
    }));
    return (
      <React.Fragment>
        <div className="bottomBar">
          <div className="bar">
            <div className="setting">
              <label htmlFor="hatRow">Hatte pr. række</label>
              <input
                type="number"
                id="hatRow"
                min="1"
                style={{ width: "70px" }}
                defaultValue={this.state.rowSize}
                onChange={(e) => this.updateState({ rowSize: e.target.value })}
              />
            </div>
            <div className="setting">
              <label htmlFor="boxSize">Bredde</label>
              <input
                type="number"
                id="boxSize"
                min="1"
                style={{ width: "70px" }}
                defaultValue={this.state.boxSize}
                onChange={(e) => this.updateState({ boxSize: e.target.value })}
              />
            </div>
            <div className="setting">
              <label htmlFor="imageZoom">Hat størrelse [%]</label>
              <input
                type="number"
                id="imageZoom"
                min="50"
                step="25"
                style={{ width: "70px" }}
                defaultValue={this.state.imageZoom}
                onChange={(e) =>
                  this.updateState({ imageZoom: e.target.value })
                }
              />
            </div>
            <div className="setting">
              <label htmlFor="bgColor">Baggrund</label>
              <input
                type="color"
                id="bgColor"
                defaultValue={this.state.bgColor}
                onChange={(e) => this.updateState({ bgColor: e.target.value })}
              />
            </div>
            <div className="setting">
              <label htmlFor="boxColor">Kasse</label>
              <input
                type="color"
                id="boxColor"
                min="0"
                defaultValue={this.state.boxColor}
                onChange={(e) => this.updateState({ boxColor: e.target.value })}
              />
            </div>
            <div className="setting">
              <label htmlFor="titleColor">Titel</label>
              <input
                type="color"
                id="titleColor"
                min="0"
                defaultValue={this.state.titleColor}
                onChange={(e) =>
                  this.updateState({ titleColor: e.target.value })
                }
              />
            </div>
            {/* <div className="setting">
					<label htmlFor="backpack">
						Rygsæk
					</label>
					<input type="text" id="backpack" defaultValue={this.state.backpack} onChange={e => this.updateState({backpack: e.target.value})}/>
				</div>
				<div className="setting">
					<label htmlFor="wishlist">
						Ønsker
					</label>
					<input type="text" id="wishlist" defaultValue={this.state.wishlist} onChange={e => this.updateState({wishlist: e.target.value})}/>
				</div> */}
            <div className="setting">
              <button onClick={() => this.import()}>Importer</button>
              <button
                onClick={() => (this.refs.textarea.value = this.exportData())}
              >
                Exporter
              </button>
            </div>
            <div className="setting">
              <textarea ref="textarea"></textarea>
            </div>
            <div className="setting">
              <button
                onClick={() => this.reset()}
                style={{
                  height: "42px",
                  backgroundColor: "#f00",
                  color: "#fff",
                  border: "0px",
                }}
              >
                Nulstil
              </button>
            </div>
          </div>
        </div>
        <div className="hatBar">
          <input
            placeholder="Søg"
            onChange={(e) =>
              this.setState({
                search: e.target.value.toLowerCase(),
                dropdownOptions: [],
                dropdownOffset: 0,
              })
            }
          />
          <div className="hats" ref="hats">
            {this.search(
              this.state.data.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
            ).map((hat, i) => {
              const selected = boxOptions.filter((box) => {
                return box.hats.some((hatID) => hat.id === hatID);
              });
              return (
                <div
                  className={"hat" + (selected.length > 0 ? " selected" : "")}
                  data-hatid={hat.id}
                  key={hat.i}
                >
                  <div className="hatInfo">
                    <Image id={hat.id} />
                    <div className="title">{hat.name}</div>
                  </div>
                  <div className="settings">
                    {/* <button onClick={() => this.toggleItem(hat.id)}>
								<i className={selected ? "fas fa-minus-circle" : "fas fa-plus-circle"} style={selected ? {color: "#dc0909"} : {}}></i>
							</button>
							<button onClick={() => this.toggleItem(hat.id, "wish")}>
								<i className={(selectedWish ? "fas " : "far ") + "fa-star"}></i>
							</button> */}

                    <DropDownButton
                      options={boxOptions}
                      showMenu={(y, y2) => {
                        console.log(
                          "y",
                          y,
                          this.refs.hats.scrollHeight,
                          this.refs.hats.scrollHeight - y,
                          this.refs.hats.getBoundingClientRect().top,
                          this.refs.hats
                        );
                        this.setState({
                          dropdownOptions: boxOptions,
                        });
                        requestAnimationFrame(() => {
                          this.refs.dropdown.style.top = "0px";
                          const hatElement = document.querySelector(
                            '[data-hatid="' + hat.id + '"]'
                          );
                          // const diff = this.refs.hats.scrollHeight - this.refs.hats.getBoundingClientRect().height;
                          // console.log(diff, (this.refs.hats.scrollHeight - this.refs.hats.scrollTop), hatElement);
                          this.setState({
                            dropdownOffset:
                              this.refs.dropdown.offsetTop -
                              hatElement.offsetTop, //this.refs.dropdown.getBoundingClientRect().top - y,//Math.abs((this.refs.hats.scrollHeight - this.refs.hats.scrollTop) - y),
                            dropdownHat: hat.id,
                          });
                        });
                      }}
                      click={(i) => {
                        const boxes = [...this.state.boxes];
                        boxes[i].hats = [...boxes[i].hats, hat.id];
                        this.updateState({
                          boxes,
                        });
                      }}
                    />
                    {/* <DropDownButton options={selected} selected={1} /> */}
                  </div>
                </div>
              );
            })}

            {/* <div className="dropdown" style={{top: `-${(this.refs?.hats?.scrollHeight ?? this.refs?.hats?.getBoundingClientRect().height ?? 0) - (this.state.dropdownOffset + ((this.refs?.hats?.scrollTop ?? 0)) + 40)}px`, visibility: this.state.dropdownOptions.length > 0 ? "initial" : "hidden"}}> */}
            <div
              className="dropdown"
              ref="dropdown"
              style={{
                top: `-${this.state.dropdownOffset - 45}px`,
                visibility:
                  this.state.dropdownOptions.length > 0 ? "initial" : "hidden",
              }}
            >
              {this.state.dropdownOptions.map((d) => (
                <div
                  className="dropdownOption"
                  key={d.id}
                  onClick={() => {
                    this.setState({
                      dropdownOptions: [],
                    });
                    const i = d.id;
                    const boxes = [...this.state.boxes];
                    boxes[i].hats = [...boxes[i].hats, this.state.dropdownHat];
                    this.updateState({
                      boxes,
                    });
                  }}
                >
                  {d.text}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="window hasUpdatedHEH">
            <div className="title">RYGSÆK</div>
            <div
              className="hats"
              style={{ backgroundColor: this.state.bgColor }}
            >
              <div className="boxHolders">
                {this.state.boxes.length === 0 && (
                  <div>Tilføj nogle boxe for at starte.</div>
                )}

                {this.state.boxes
                  .map((d, i) => ({ ...d, i }))
                  // .filter(d => d.hats.length !== 0)
                  .map((data, i) => (
                    <Box
                      title={data.title}
                      titleColor={this.state.titleColor}
                      data={this.state.data}
                      rowSize={this.state.rowSize}
                      imageZoom={this.state.imageZoom}
                      boxSize={this.state.boxSize}
                      boxColor={this.state.boxColor}
                      hats={data.hats}
                      deleteBox={() =>
                        this.updateState({
                          boxes: this.state.boxes.filter(
                            (_, boxI) => i !== boxI
                          ),
                        })
                      }
                      updateTitle={(value) =>
                        this.updateBox(data.i, "title", value)
                      }
                      toggleItem={(value) =>
                        this.updateBox(data.i, "hats", value)
                      }
                    />
                  ))}

                {/* {
							this.state.selected.length > 0 && 
						<div className="box">
							<div className="boxTitle">{this.state.backpack}</div>
							<div className="selectedHats" style={{gridTemplateColumns: `repeat(${this.state.rowSize}, 1fr)`, maxWidth: `${this.state.boxSize}px`, backgroundColor: this.state.boxColor}}>
								{
										this.state.selected.map(item => {
											const hat = this.state.data[item];
											return (<div className="hat" onClick={() => this.toggleItem(item)} key={item}>
											<img src={`https://hp3.clonex.dk/api.php?type=img&value=${hat.id}`}/>
											{
												this.state.showNames && <div className="title">{hat.name}</div>
											}
										</div>);
										})
								}
							</div>
						</div>
					} */}

                {/* {
						this.state.selectedWish.length > 0 && <div className="box">
						<div className="boxTitle">{this.state.wishlist}</div>
						<div className="selectedHats" style={{gridTemplateColumns: `repeat(${this.state.rowSize}, 1fr)`, maxWidth: `${this.state.boxSize}px`, backgroundColor: this.state.boxColor}}>
							{
									this.state.selectedWish.map(item => {
										const hat = this.state.data[item];
										return (<div className="hat" onClick={() => this.toggleItem(item, "wish")} key={item}>
										<img src={`https://hp3.clonex.dk/api.php?type=img&value=${hat.id}`}/>
										{
											this.state.showNames && <div className="title">{hat.name}</div>
										}
									</div>);
									})
							}
						</div>
					</div>
					} */}
              </div>
            </div>

            <button onClick={() => this.addBox()} className="newBoxBtn">
              + Tilføj box
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
